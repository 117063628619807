<template>
  <section>
    <h2 class="mb-4">Nuovo Progetto Manutenzione</h2>

    <div>
      <form-wizard ref="projectWizard" color="#018001" :title="null" :subtitle="null" shape="square"
        finish-button-text="Conferma" back-button-text="Indietro" next-button-text="Avanti" class="mb-3">

        <wizard-step slot-scope="props" slot="step" :tab="props.tab" :transition="props.transition" :index="props.index">
        </wizard-step>

        <!-- Anagrafica Cliente -->
        <tab-content title="Anagrafica Cliente" :before-change="validationCustomer">
          <!--  -->

          <validation-observer ref="customerTab">
            <b-row class="my-2">
              <b-col md="6">
                <b-form-group label="Nome Progetto" for="project_name">
                  <validation-provider #default="{ errors }" name="Nome Progetto" rules="">
                    <b-form-input id="project_name" v-model="project_name" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_name"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <b-row class="my-2">
              <b-col md="6">
                <b-form-group label="Ragione Sociale" for="project_customer_companyname">
                  <validation-provider #default="{ errors }" name="Ragione Sociale" rules="required">
                    <b-form-input id="project_customer_companyname" v-model="project_customer_companyname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_companyname"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Partita IVA" for="project_customer_vatnumber">
                  <validation-provider #default="{ errors }" name="Partita IVA" rules="required">
                    <b-form-input id="project_customer_vatnumber" v-model="project_customer_vatnumber"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_vatnumber"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Nome Amministratore/Referente" for="project_customer_ceo_name">
                  <validation-provider #default="{ errors }" name="Nome Amministratore/Referente" rules="">
                    <b-form-input id="project_customer_ceo_name" v-model="project_customer_ceo_name"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_ceo_name"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Cognome Amministratore/Referente" for="project_customer_ceo_surname">
                  <validation-provider #default="{ errors }" name="Cognome Amministratore/Referente" rules="">
                    <b-form-input id="project_customer_ceo_surname" v-model="project_customer_ceo_surname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_ceo_surname"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <b-row class="my-5">
              <b-col md="3"></b-col>
              <b-col md="3" class="d-flex justify-content-center align-items-center">
                <upload-image :field_name="'project_customer_logo_path'" label="Logo cliente"
                      :currentFile="project_customer_logo_path.attachment ? BACKEND_BASE_URL + '/' + project_customer_logo_path.attachment : null"
                      :uploading="project_customer_logo_path.uploading"
                      @new-file="uploadDocument($event, project_customer_logo_path)"
                      @reset-file="project_customer_logo_path.attachment = null"></upload-image>
              </b-col>
              <b-col md="3" class="d-flex justify-content-center align-items-center">
                <upload-image :field_name="'project_customer_splashimage_path'" label="Foto stabilimento"
                      :currentFile="project_customer_splashimage_path.attachment ? BACKEND_BASE_URL + '/' + project_customer_splashimage_path.attachment : null"
                      :uploading="project_customer_splashimage_path.uploading"
                      @new-file="uploadDocument($event, project_customer_splashimage_path)"
                      @reset-file="project_customer_splashimage_path.attachment = null"></upload-image>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>

            <b-row class="my-2">
              <b-col md="4">
                <b-form-group label="Indirizzo (sede legale)" for="project_customer_address">
                  <validation-provider #default="{ errors }" name="Indirizzo (sede legale)" rules="required">
                    <b-form-input id="project_customer_address" :state="errors.length > 0 ? false : null"
                      v-model="project_customer_address" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Città (sede legale)" for="project_customer_city">
                      <validation-provider #default="{ errors }" name="Città (sede legale)" rules="required">
                        <b-form-input id="project_customer_city" v-model="project_customer_city"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="CAP (sede legale)" for="project_customer_zipcode">
                      <validation-provider #default="{ errors }" name="CAP (sede legale)" rules="required">
                        <b-form-input id="project_customer_zipcode" :state="errors.length > 0 ? false : null"
                          v-model="project_customer_zipcode" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Provincia (sede legale)" for="project_customer_province">
                      <validation-provider #default="{ errors }" name="Provincia (sede legale)" rules="required">
                        <b-form-select :items="provinces" v-model="project_customer_province"
                          :state="errors.length > 0 ? false : null">
                          <b-form-select-option value="" disabled>Seleziona Provincia</b-form-select-option>
                          <b-form-select-option v-for="province in provinces" :key="province.geo_code"
                            :value="province.geo_code">{{ province.geo_name }}</b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Nazione (sede legale)" for="project_customer_country">
                      <validation-provider #default="{ errors }" name="Nazione (sede legale)" rules="required">
                        <b-form-input id="project_customer_country" :state="errors.length > 0 ? false : null"
                          v-model="project_customer_country" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>


            <b-row>
              <b-col md="12">
                <b-form-group label="Descrizione attitivà e storia del cliente" for="project_customer_about">
                  <validation-provider #default="{ errors }" name="Descrizione attività e storia del cliente" rules="">
                    <b-form-textarea id="project_customer_about" :rows="8" :state="errors.length > 0 ? false : null"
                      placeholder="Scrivi..." v-model="project_customer_about" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <a href="#" @click="selected_help_field = help_field.project_customer_about"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group label="Descrizione intervento" for="project_customer_desc_intervention">
                  <validation-provider #default="{ errors }" rules="required" name="Descrizione intervento">
                    <b-form-textarea id="project_customer_desc_intervention" v-model="project_customer_desc_intervention"
                      :rows="8" :state="errors.length > 0 ? false : null" placeholder="Scrivi..." />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <a href="#" @click="selected_help_field = help_field.project_customer_desc_intervention"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group label="Tipologia di intervento" for="project_customer_type_intervention">
                  <validation-provider #default="{ errors }" name="Tipologia di intervento" rules="">
                    <b-form-textarea id="project_customer_type_intervention" :rows="8"
                      :state="errors.length > 0 ? false : null" placeholder="Scrivi"
                      v-model="project_customer_type_intervention" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <a href="#" @click="selected_help_field = help_field.project_customer_type_intervention"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <fieldset class="border p-1 bg-light">
              <legend class="text-center mx-auto bg-white border border-dark px-2"
                style="width: min-content; white-space: nowrap">Sito Intervento</legend>
              <div class="d-flex justify-content-center">
                <a href="#" @click="selected_help_field = help_field.project_siteinspection"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </div>
              <b-row class="my-2">
                <b-col md="4">
                  <b-form-group label="Indirizzo" for="project_siteinspection_address">
                    <validation-provider #default="{ errors }" name="Indirizzo (Sito Intervento)" rules="required">
                      <b-form-input id="project_siteinspection_address" v-model="project_siteinspection_address"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Città" for="project_siteinspection_city">
                        <validation-provider #default="{ errors }" name="Città (Sito Intervento)" rules="required">
                          <b-form-input id="project_siteinspection_city" v-model="project_siteinspection_city"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="CAP" for="project_siteinspection_zipcode">
                        <validation-provider #default="{ errors }" name="CAP (Sito Intervento)" rules="required">
                          <b-form-input id="project_siteinspection_zipcode" v-model="project_siteinspection_zipcode"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Provincia" for="project_siteinspection_province">
                        <validation-provider #default="{ errors }" name="Provincia (Sito Intervento)" rules="required">
                          <b-form-select :items="provinces" v-model="project_siteinspection_province"
                            :state="errors.length > 0 ? false : null">
                            <b-form-select-option value="" disabled>Seleziona Provincia</b-form-select-option>
                            <b-form-select-option v-for="province in provinces" :key="province.geo_code"
                              :value="province.geo_code">{{ province.geo_name }}</b-form-select-option>
                          </b-form-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Nazione" for="project_siteinspection_country">
                        <validation-provider #default="{ errors }" name="Nazione (Sito Intervento)" rules="required">
                          <b-form-input id="project_siteinspection_country" v-model="project_siteinspection_country"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-3">
                <b-col md="4">
                  <upload-image :field_name="'project_siteinspection_photo_1_path'" label="Foto 1"
                      :currentFile="project_siteinspection_photo_1_path.attachment ? BACKEND_BASE_URL + '/' + project_siteinspection_photo_1_path.attachment : null"
                      :uploading="project_siteinspection_photo_1_path.uploading"
                      @new-file="uploadDocument($event, project_siteinspection_photo_1_path)"
                      @reset-file="project_siteinspection_photo_1_path.attachment = null"></upload-image>
                </b-col>

                <b-col md="4">
                  <upload-image :field_name="'project_siteinspection_photo_2_path'" label="Foto 2"
                      :currentFile="project_siteinspection_photo_2_path.attachment ? BACKEND_BASE_URL + '/' + project_siteinspection_photo_2_path.attachment : null"
                      :uploading="project_siteinspection_photo_2_path.uploading"
                      @new-file="uploadDocument($event, project_siteinspection_photo_2_path)"
                      @reset-file="project_siteinspection_photo_2_path.attachment = null"></upload-image>

                </b-col>

                <b-col md="4">
                  <upload-image :field_name="'project_siteinspection_attachment'" label="Planimetria stabilimento o Layout Impianto"
                      :currentFile="project_siteinspection_attachment_path.attachment ? BACKEND_BASE_URL + '/' + project_siteinspection_attachment_path.attachment : null"
                      :uploading="project_siteinspection_attachment_path.uploading"
                      @new-file="uploadDocument($event, project_siteinspection_attachment_path)"
                      @reset-file="project_siteinspection_attachment_path.attachment = null"></upload-image>
                </b-col>
              </b-row>
            </fieldset>

            <hr />

            <b-row>
              <b-col md="4">
                <b-form-group label="Dettagli impianto" for="project_implant_details">
                  <validation-provider #default="{ errors }" name="Dettagli impianto" rules="">
                    <b-form-textarea id="project_implant_details" :rows="8" :state="errors.length > 0 ? false : null"
                      placeholder="Scrivi" v-model="project_implant_details" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_implant_details"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="4">
                <b-form-group label="Scopo di utilizzo" for="project_implant_scope">
                  <validation-provider #default="{ errors }" name="Scopo di Utilizzo" rules="required">
                    <b-form-select id="project_implant_scope" :options="implant_scope_items"
                      :state="errors.length > 0 ? false : null" placeholder="Scrivi" v-model="project_implant_scope" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_implant_scope"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>

                <b-form-group
                  v-if="userData && userData.account_type_code != 'utilizzatore' && userData.account_type_code != 'installatore'"
                  label="È prevista attività di manutenzione/riparazione/recupero e riutilizzo o smaltimento di fluido refrigerante o parti di impianto?">
                  <validation-provider #default="{ errors }" :name="'question_1'" rules="required">
                    <b-form-radio-group :id="'question_1'" v-model="question_1" :state="errors.length > 0 ? false : null">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a v-if="userData.account_type_code != 'utilizzatore' && userData.account_type_code != 'installatore'"
                  href="#" @click="selected_help_field = help_field.question_1"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>

                <b-form-group label="È presente una pista in ghiaccio? (Es. pista di pattinaggio sul ghiaccio)">
                  <validation-provider #default="{ errors }" :name="'question_2'" rules="required">
                    <b-form-radio-group :id="'question_2'" v-model="question_2" :state="errors.length > 0 ? false : null">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.question_2"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="4">
                <b-form-group
                  v-if="userData && userData.account_type_code != 'utilizzatore' && userData.account_type_code != 'installatore'"
                  label="L'attività prevista è di tipo &quot;Manutenzione Ordinaria&quot;?">
                  <validation-provider #default="{ errors }" :name="'question_3'" rules="required">
                    <b-form-radio-group :id="'question_3'" v-model="question_3" :state="errors.length > 0 ? false : null">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a v-if="userData.account_type_code != 'utilizzatore' && userData.account_type_code != 'installatore'"
                  href="#" @click="selected_help_field = help_field.question_3"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content title="Refrigerante" :before-change="validationFormDescription2">
          <validation-observer ref="description2Rules">

            <b-row>
              <b-col md="6">
                <b-form-group label="Tipologia Refrigerante">
                  <validation-provider #default="{ errors }" name="Tipologia Refrigerante" rules="required">
                    <b-input-group>
                      <b-form-input hidden id="Tipologia Refrigerante" v-model="refrigerant_type" readonly
                        :state="errors.length > 0 ? false : null" />

                      <b-form-input readonly :value="refrigerant_type.numero_refrigerante"></b-form-input>

                      <b-input-group-append>
                        <b-button variant="primary" @click="$bvModal.show('modal-refrigerant-type')"
                          v-html="refrigerant_type == '' ? 'Seleziona' : 'Modifica Selezione'"></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Misure di Sicurezza -->

        <tab-content title="Misure di Sicurezza">
          <validation-observer ref="securityRules">

            <fieldset class="border p-1 bg-light mt-2" v-if="project_user_role == 'Manutentore' || project_user_role == 'Installatore e Manutentore'">


              <b-row>

                <b-col md="12">
                  <b-form-group
                    label="Il personale impiegato per le operazioni di manutenzione e supervisione sono adeguatamente formati ed istruiti ?">
                    <validation-provider #default="{ errors }" name="security_question_3_1" rules="required">
                      <b-form-radio-group id="security_question_3_1" v-model="security_question_3_1"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Sono previste attività di riparazione?">
                    <validation-provider #default="{ errors }" name="security_question_3_2" rules="required">
                      <b-form-radio-group id="security_question_3_2" v-model="security_question_3_2"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">è stata effettuata la procedura del vuoto secondo le prescrizioni normative? (<a
                        target="_blank"
                        v-bind:href="STORAGE_URL + '/PRO.01.11-Guida_per_la_procedura_del_vuoto.pdf'">Consulta la Guida
                        alla Procedura di vuoto (PRO.01,11)</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_3" rules="required">
                      <b-form-radio-group id="security_question_3_3" v-model="security_question_3_3"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>



                <b-col md="12">
                  <b-form-group label="È previsto il cambio di refrigerante?">
                    <validation-provider #default="{ errors }" name="security_question_3_4" rules="required">
                      <b-form-radio-group id="security_question_3_4" v-model="security_question_3_4"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="security_question_3_4 == 'Yes'">
                  <b-form-group label="Allegare documento con registrazione dei valori misurati pre e post intervento">
                    <upload-document :field_name="'security_question_3_4_data'"
                      :currentFile="security_question_3_4_data.attachment ? BACKEND_BASE_URL + '/' + security_question_3_4_data.attachment : null"
                      :uploading="security_question_3_4_data.uploading"
                      @new-file="uploadDocument($event, security_question_3_4_data)"
                      @reset-file="security_question_3_4_data.attachment = null"></upload-document>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                  <b-form-group label="È prevista attività di recupero per riutilizzo nello stesso sistema?">
                    <validation-provider #default="{ errors }" name="security_question_3_5" rules="required">
                      <b-form-radio-group id="security_question_3_5" v-model="security_question_3_5"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di recupero per riutilizzo in sistema simile?">
                    <validation-provider #default="{ errors }" name="security_question_3_6" rules="required">
                      <b-form-radio-group id="security_question_3_6" v-model="security_question_3_6"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="security_question_3_5 == 'Yes' || security_question_3_6 == 'Yes'">
                  <b-form-group label="Allegare certificato di taratura degli strumenti utilizzati">
                    <upload-document :field_name="'security_attachment_taratura'"
                      :currentFile="security_attachment_taratura.attachment ? BACKEND_BASE_URL + '/' + security_attachment_taratura.attachment : null"
                      :uploading="security_attachment_taratura.uploading"
                      @new-file="uploadDocument($event, security_attachment_taratura)"
                      @reset-file="security_attachment_taratura.attachment = null"></upload-document>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="è prevista attività di Trasferimento del fluido refrigerante in  sezioni differenti di impianto?">
                    <validation-provider #default="{ errors }" name="security_question_3_7" rules="required">
                      <b-form-radio-group id="security_question_3_7" v-model="security_question_3_7"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="security_question_3_7 == 'Yes'">
                  <b-form-group label="Per il trasferimento è stato utilizzato il compressore dell'impianto?">
                    <validation-provider #default="{ errors }" name="security_question_3_8" rules="required">
                      <b-form-radio-group id="security_question_3_8" v-model="security_question_3_8"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="security_question_3_7 == 'Yes'">
                  <b-form-group label="Il fluido refrigerante è stato trasferito in un contenitore?">
                    <validation-provider #default="{ errors }" name="security_question_3_9" rules="required">
                      <b-form-radio-group id="security_question_3_9" v-model="security_question_3_9"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="è prevista attività di trasporto del refrigerante? ">
                    <validation-provider #default="{ errors }" name="security_question_3_10" rules="required">
                      <b-form-radio-group id="security_question_3_10" v-model="security_question_3_10"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di conservazione del fluido refrigerante recuperato?">
                    <validation-provider #default="{ errors }" name="security_question_3_11" rules="required">
                      <b-form-radio-group id="security_question_3_11" v-model="security_question_3_11"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di smaltimento del fluido refrigerante recuperato?">
                    <validation-provider #default="{ errors }" name="security_question_3_12" rules="required">
                      <b-form-radio-group id="security_question_3_12" v-model="security_question_3_12"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">La manipolazione del refrigerante è stata gestita secondo le prescrizioni di cui
                      all'Allegato C della UNI EN 378-4:2020 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_alla_manipolazione_e_stoccaggio_di_refrigeranti.pdf'">Guida
                        alla manipolazione</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_13" rules="required">
                      <b-form-radio-group id="security_question_3_13" v-model="security_question_3_13"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è
                        richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="I manutentori sono provvisti di DPI necessari allo scopo, controllati e manutenuti come prescritto da normativa?">
                    <validation-provider #default="{ errors }" name="security_question_3_14" rules="required">
                      <b-form-radio-group id="security_question_3_14" v-model="security_question_3_14"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Si dichiara di aver messo in campo tutte le azioni necessarie e propedeutiche
                      all'intervento come riportate nella (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_per_le_riparazioni_di_apparecchiature_che_utilizzano_refrigeranti_infiammabili.pdf'">Guida
                        per la riparazione di apparecchiature funzionanti con refrigeranti infiammabili</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_15" rules="required">
                      <b-form-radio-group id="security_question_3_15" v-model="security_question_3_15"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Prima e dopo l'intervento effettuato, sono state effettuate le ispezioni di
                      servizio come indicato in Allegato D della UNI EN 378-4:2020 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_alle_ispezioni_in_servizio.pdf'">Guida alle ispezioni in
                        servizio</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_16" rules="required">
                      <b-form-radio-group id="security_question_3_16" v-model="security_question_3_16"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Sono state verificate le prescrizioni di cui al paragrafo 5 della UNI EN
                      378-3:2021 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/check_list_controllo_sala_macchine.pdf'">Prescrizioni sala
                        macchine</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_17" rules="required">
                      <b-form-radio-group id="security_question_3_17" v-model="security_question_3_17"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                  <b-form-group label="L'intervento richiede attività di saldatura/brasatura?">
                    <validation-provider #default="{ errors }" name="security_question_3_18" rules="required">
                      <b-form-radio-group id="security_question_3_18" v-model="security_question_3_18"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="security_question_3_18 == 'Yes'">
                  <b-form-group label="Allegare qualifica del personale impiegato">
                    <upload-document :field_name="'security_attachment_taratura'"
                      :currentFile="security_question_3_18_data.attachment ? BACKEND_BASE_URL + '/' + security_question_3_18_data.attachment : null"
                      :uploading="security_question_3_18_data.uploading"
                      @new-file="uploadDocument($event, security_question_3_18_data)"
                      @reset-file="security_question_3_18_data.attachment = null"></upload-document>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="la valvola limitatrice di pressione è stata sostituita?">
                    <validation-provider #default="{ errors }" name="security_question_3_19" rules="required">
                      <b-form-radio-group id="security_question_3_19" v-model="security_question_3_19"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Si dichiara di aver verificato la disponibilità dei documenti come prescritto al
                      Capitolo 10 della UNI EN 378-3:2021 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/check_list_controllo_sala_macchine.pdf'">Manuali ed istruzioni</a>)?
                    </slot>
                    <validation-provider #default="{ errors }" name="security_question_3_20" rules="required">
                      <b-form-radio-group id="security_question_3_20" v-model="security_question_3_20"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Sono disponibili i DPI prescritti da Normativa? (<a
                        target="_blank"
                        v-bind:href="STORAGE_URL + '/RO.01.01-Check_List_controllo_DPI.pdf'">Allegato Pro 01,01</a>)?</slot>

                    <validation-provider #default="{ errors }" name="security_question_3_21" rules="required">
                      <b-form-radio-group id="security_question_3_21" v-model="security_question_3_21"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="è disponibile il piano di intervento redatto dall'impresa manutentirice">
                    <validation-provider #default="{ errors }" name="security_question_3_22" rules="required">
                      <b-form-radio-group id="security_question_3_22" v-model="security_question_3_22"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="security_question_3_22 == 'Yes'">
                  <b-form-group label="Allegare il piano di intervento redatto dall'impresa manutentirice">
                    <upload-document :field_name="'security_question_3_22_data'"
                      :currentFile="security_question_3_22_data.attachment ? BACKEND_BASE_URL + '/' + security_question_3_22_data.attachment : null"
                      :uploading="security_question_3_22_data.uploading"
                      @new-file="uploadDocument($event, security_question_3_22_data)"
                      @reset-file="security_question_3_22_data.attachment = null"></upload-document>
                  </b-form-group>
                </b-col>

              </b-row>

            </fieldset>


          </validation-observer>


        </tab-content>

        <!-- Completato -->


        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left">
            <b-button v-if="props.activeTabIndex > 0" @click="$refs.projectWizard.prevTab()">
              Indietro</b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button variant="primary" :disabled="loading"
              @click="props.activeTabIndex == 2 ? validationFormSecurity() : $refs.projectWizard.nextTab()"
              class="wizard-footer-right">Avanti</b-button>


          </div>
        </template>
      </form-wizard>
    </div>






    <b-modal id="modal-refrigerant-type" title="Seleziona Refrigerante" size="xl" ok-only ok-title="Conferma" scrollable
      style="overflow-y: hidden">

      <vue-autosuggest v-model="refrigerant_type.numero_refrigerante" :suggestions="filteredOptions" :limit="10"
        :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: 'Ricerca refrigerante' }"
        @input="onInputChange" @selected="onSelect">
        <template slot="before-input">
          <label>Cerca:</label>
        </template>
        <template slot-scope="{suggestion}">
          <span class="my-suggestion-item">{{ suggestion.item.numero_refrigerante }}</span>
        </template>
      </vue-autosuggest>

      <b-tabs style="max-height: 100%">
        <b-tab style="height: calc(100vh - 262px); overflow: auto" v-for="(gas, index) in refrigerantGases" :key="index"
          :title="Object.keys(refrigerantGases[index])[0]">
          <b-form-group>

            <b-form-radio-group stacked>
              <b-table :items="gas[Object.keys(refrigerantGases[index])]" :fields="['seleziona_refrigerante']">
                <template #cell(seleziona_refrigerante)="data">
                  <b-form-radio v-model="refrigerant_type" class="my-1" :value="data.item"
                    :disabled="data.item.record_status == 'inactive'">
                    {{ data.item.numero_refrigerante }}
                  </b-form-radio>
                </template>
              </b-table>
            </b-form-radio-group>

          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal id="modal-error" title="Attenzione" size="lg" cancel-disabled ok-title="OK">
      <b-card-text>Ci sono dei campi non compilati.</b-card-text>
    </b-modal>

    <b-modal id="modal-conferma" title="Conferma Dati" size="lg" cancel-title="Annulla" ok-title="Conferma"
      @ok="validationFormSecurity">
      <validation-observer ref="nameRules">
        <b-card-text>Sei sicuro di voler procedere?</b-card-text>
      </validation-observer>
    </b-modal>

    <b-modal @hidden="selected_help_field = ''" id="modal-info" title="Guida alla compilazione" size="lg" cancel-disabled
      ok-only ok-title="OK">
      <b-card-text v-html="selected_help_field"></b-card-text>
    </b-modal>
  </section>
</template>

<script>

import { VueAutosuggest } from "vue-autosuggest";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BTable,
  BAlert,
  BTabs,
  BTab,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BCardText,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { Requests } from "@/api/requests.js";

//Select Menu Data

import * as provinces from "@/assets/data/province.json";
import * as categories from "@/assets/data/categories.json";
import * as allocation_classes from "@/assets/data/allocation_classes.json";
import installation_typologies from "@/assets/data/installation_typologies.js";
import { STORAGE_URL, BACKEND_BASE_URL } from '@/config';
import UploadDocument from '@/@core/components/upload-document/UploadDocument.vue';
import UploadImage from '@/@core/components/upload-image/UploadImage.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    WizardStep,
    TabContent,
    BTable,
    BAlert,
    BTabs,
    BTab,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BCardText,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
    BFormTextarea,
    BSpinner,
    vSelect,
    VueAutosuggest,
    BFormInvalidFeedback,
    ToastificationContent,
    UploadDocument,
    UploadImage
  },

  created() {
    localize("it");
    this.getRefrigerantGasList();
    this.getRefrigerantGasSuggestList();
  
    if (this.userData.account_type_code.match(new RegExp('manutentore', 'i'))) {
      this.project_user_role = this.userData.account_type_label;
      console.log(this.project_user_role);
    }
  },

  mounted() {
    this.$bvModal.show('new-project-maint-preamble');
  },

  computed: {
    userData: function () {
      return JSON.parse(localStorage.getItem("user"));
    }
  },

  data: () => ({
    STORAGE_URL: STORAGE_URL,
    BACKEND_BASE_URL: BACKEND_BASE_URL,
    volume: [],
    summary: '',
    dummy: [],
    dummy2: [],
    project_id: null,

    allocation_fields: [
      "classe",
      "definizione",
      "caratteristiche_generali",
    ],
    typology_fields: [
      "definizione",
      "immagini",
    ],
    internal_unit_positions: [
      {
        value: "1",
        text: "Piano"
      },
      {
        value: "2",
        text: "Su parete"
      },
      {
        value: "3",
        text: "Su finestra"
      },
      {
        value: "4",
        text: "Soffitto"
      }
    ],
    fields: ["categoria", "livello_restrizione", "caratteristiche_generali", "esempi"],
    selected_help_field: "",
    help_field: {
      project_name: "Scegli un nome da assegnare al tuo progetto (Campo Facoltativo)",
      project_customer_companyname: "Inserire la ragione sociale del tuo cliente (Campo obbligatorio)",
      project_customer_vatnumber: "Inserire la P.IVA del tuo cliente (Campo obbligatorio)",
      project_customer_ceo_name: "Inserire il Nome del referente con cui ti sei interfacciato o il nome dell'Amministratore della società (Campo Facoltativo)",
      project_customer_ceo_surname: "Inserire il Cognome del referente con cui ti sei interfacciato o il nome dell'Amministratore della società (Campo Facoltativo)",
      project_customer_about: "Inserire una descrizione dell'attività svolta dal tuo cliente e l'eventuale storia. (Campo Facoltativo)",
      project_customer_desc_intervention: "Inserire una descrizione dell'intervento che si deve effettuare. (Campo Facoltativo).<br><br>N.B. all'interno del documento finale le informazioni da te inserite (parte in rosso) compariranno come segue:<br><br>L'intervento previsto riguarda l'attività di <span style=\"color: red\">“analisi dei rischi connessa all'utilizzo e manutenzione delle celle refrigerate“</span> da effettuarsi per conto della...",
      project_customer_type_intervention: "Inserisci la tipologia di intervento che si deve effettuare (Campo Facoltativo).<br><br>N.B. all'interno del documento finale le informazioni da te inserite (parte in rosso) compariranno come segue:<br><br>L'organizzazione ha fatto richiesta di <span style=\"color: red\">“consulenza in merito all'analisi di dettaglio circa i quantitativi massimi consentiti in conformità alla UNI EN 378 ed eventuale applicazione di misure di sicurezza aggiuntive“</span>",
      project_technical: "Inserisci i nominativi e referenze mail dei tecnici incaricati dalla tua azienda (Campo Facoltativo)",
      project_siteinspection: "Inserisce i dati relativi al sito oggetto di intervento / analisi (Campo obbligatorio)",
      project_implant_details: "Inserisci una breve descrizione dell'impianto da realizzare / realizzato (campo facoltativo)",
      project_implant_scope: "Seleziona lo scopo di utilizzo dell'impianto (Campo obbligatorio)",
      question_1: "selezionare il “SI” se è prevista almeno una delle seguenti attività: manutentiva o di riparazione su parte di impianto, riutilizzo o smaltimento del gas. (Campo obbligatorio)",
      question_2: "Indicare se l'impianto è a servizio di una pista di pattinaggio sul ghiaccio.",
      project_type: "Indicare in quale ambito si interviene (Campo obbligatorio)",
      security_question_3_0: "",
      security_question_3_1: "",
      security_question_3_2: "",
      security_question_3_3: "",
      security_question_3_4: "",
      security_question_3_5: "",
      security_question_3_6: "",
      security_question_3_7: "",
      security_question_4_0: "",
      security_question_4_1: "",
      security_question_4_2: "",
      security_question_4_3: "",
      security_question_4_4: "",
      security_question_4_5: "",
      security_question_4_6: "",
      security_question_4_7: "",
      prescription_respect: "",
    },
    implant_scope_items: [
      {
        value: null,
        text: 'Seleziona...',
        disabled: true,
      },
      {
        value: "Refrigerazione",
        text: 'Refrigerazione',
      },
      {
        value: "Condizionamento",
        text: "Condizionamento",
      }
    ],


    userRoles: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
      {
        value: "Installatore",
        text: "Installatore",
      },
      {
        value: "Manutentore",
        text: "Manutentore",
      },
      {
        value: "Utilizzatore",
        text: "Utilizzatore",
      }
    ],




    provinces: provinces.default,

    refrigerantGases: [],
    refrigerantGasesSuggest: [],
    filteredOptions: [],

    refrigerantGasesNumber: [],

    project_name: "",
    project_code: "",
    project_customer_companyname: '',
    project_customer_vatnumber: '',
    project_customer_ceo_name: '',
    project_customer_ceo_surname: '',
    project_customer_logo_path: {
        attachment: '',
        uploading: false
      },
      
    project_customer_splashimage_path: {
      attachment: '',
      uploading: false
    },
    project_customer_address: '',
    project_customer_city: '',
    project_customer_zipcode: '',
    project_customer_province: '',
    project_customer_country: '',
    project_customer_about: '',
    project_customer_desc_intervention: '',
    project_customer_type_intervention: '',
    project_siteinspection_address: '',
    project_siteinspection_city: '',
    project_siteinspection_zipcode: '',
    project_siteinspection_province: '',
    project_siteinspection_country: '',
    project_siteinspection_photo_1_path: {
      attachment: '',
      uploading: false
    },
    project_siteinspection_photo_2_path: {
      attachment: '',
      uploading: false
    },
    project_siteinspection_attachment_path: {
      attachment: '',
      uploading: false
    },
    project_implant_details: '',
    project_implant_scope: null,
    project_user_role: null,
    project_type: null,
    project_access_category: null,
    project_plant_allocation: null,
    project_plant_type: null,
    question_1: '',
    question_2: '',
    question_3: '',
    refrigerant_type: '',
    security_question_3_1: '',
    security_question_3_2: '',
    security_question_3_3: '',
    security_question_3_4: '',
    security_question_3_4_data: {
      attachment: "",
      uploading: false,
    },
    security_attachment_taratura: {
      attachment: "",
      uploading: false,
    },
    security_question_3_5: '',
    security_question_3_6: '',
    security_question_3_7: '',
    security_question_3_8: '',
    security_question_3_9: '',
    security_question_3_10: '',
    security_question_3_11: '',
    security_question_3_12: '',
    security_question_3_13: '',
    security_question_3_14: '',
    security_question_3_15: '',
    security_question_3_16: '',
    security_question_3_17: '',
    security_question_3_18: '',
    security_question_3_18_data: {
      attachment: "",
      uploading: false
    },
    security_question_3_19: '',
    security_question_3_20: '',
    security_question_3_21: '',
    security_question_3_22: '',
    security_question_3_22_data: {
      attachment: "",
      uploading: false
    },
    refrigerant_type: '',
    basement_volume: '',
    uploadedFile: '',
    required,
    email,
    nameList: [],
    tabIndex: 1,
    isValidate: false,
    disabled: false,

    //Helpers

    uploadLogoLoading: false,
    uploadBuildingPhotoLoading: false,
    uploadPhoto1Loading: false,
    uploadPhoto2Loading: false,
    uploadAttachmentLoading: false,
    loading: false,
  }),

  methods: {

    async createProject() {
      this.loading = true;

      let data = new FormData();

      data.append('project_name', this.project_name)
      data.append('project_customer_companyname', this.project_customer_companyname ?? '')
      data.append('project_customer_vatnumber', this.project_customer_vatnumber ?? '')
      data.append('project_customer_ceo_name', this.project_customer_ceo_name ?? '')
      data.append('project_customer_ceo_surname', this.project_customer_ceo_surname ?? '')
      data.append('project_customer_logo_path', this.project_customer_logo_path.attachment ?? '')
      data.append('project_customer_splashimage_path', this.project_customer_splashimage_path.attachment ?? '')
      data.append('project_customer_address', this.project_customer_address ?? '')
      data.append('project_customer_city', this.project_customer_city ?? '')
      data.append('project_customer_zipcode', this.project_customer_zipcode ?? '')
      data.append('project_customer_province', this.project_customer_province ?? '')
      data.append('project_customer_country', this.project_customer_country ?? '')
      data.append('project_customer_about', this.project_customer_about ?? '')
      data.append('project_customer_desc_intervention', this.project_customer_desc_intervention ?? '')
      data.append('project_customer_type_intervention', this.project_customer_type_intervention ?? '')
      data.append('project_siteinspection_address', this.project_siteinspection_address ?? '')
      data.append('project_siteinspection_city', this.project_siteinspection_city ?? '')
      data.append('project_siteinspection_zipcode', this.project_siteinspection_zipcode ?? '')
      data.append('project_siteinspection_province', this.project_siteinspection_province ?? '')
      data.append('project_siteinspection_country', this.project_siteinspection_country ?? '')
      data.append('project_siteinspection_photo_1_path', this.project_siteinspection_photo_1_path.attachment ?? '')
      data.append('project_siteinspection_photo_2_path', this.project_siteinspection_photo_2_path.attachment ?? '')
      data.append('project_siteinspection_attachment_path', this.project_siteinspection_attachment_path.attachment ?? '')
      data.append('project_implant_details', this.project_implant_details ?? '')
      data.append('project_implant_scope', this.project_implant_scope ?? '')
      data.append('project_type', this.project_type ?? '')
      data.append('project_access_category', this.project_access_category ?? '')
      data.append('project_plant_allocation', this.project_plant_allocation ?? '')
      data.append('project_plant_type', this.project_plant_type ?? '')

      data.append('question_1', this.question_1 ?? '')
      data.append('question_2', this.question_2 ?? '')
      data.append('question_3', this.question_3 ?? '')
      data.append('security_question_3_1', this.security_question_3_1 ?? '')
      data.append('security_question_3_2', this.security_question_3_2 ?? '')
      data.append('security_question_3_3', this.security_question_3_3 ?? '')
      data.append('security_question_3_4', this.security_question_3_4 ?? '')
      data.append('security_question_3_4_data', this.security_question_3_4_data?.attachment ?? '')
      data.append('security_question_3_5', this.security_question_3_5 ?? '')
      data.append('security_question_3_6', this.security_question_3_6 ?? '')
      data.append('security_question_3_7', this.security_question_3_7 ?? '')
      data.append('security_question_3_8', this.security_question_3_8 ?? '')
      data.append('security_question_3_9', this.security_question_3_9 ?? '')
      data.append('security_attachment_taratura', this.security_attachment_taratura?.attachment ?? '');
      data.append('security_question_3_10', this.security_question_3_10 ?? '')
      data.append('security_question_3_11', this.security_question_3_11 ?? '')
      data.append('security_question_3_12', this.security_question_3_12 ?? '')
      data.append('security_question_3_13', this.security_question_3_13 ?? '')
      data.append('security_question_3_14', this.security_question_3_14 ?? '')
      data.append('security_question_3_15', this.security_question_3_15 ?? '')
      data.append('security_question_3_16', this.security_question_3_16 ?? '')
      data.append('security_question_3_17', this.security_question_3_17 ?? '')
      data.append('security_question_3_18', this.security_question_3_18 ?? '')
      data.append('security_question_3_18_data', this.security_question_3_18_data?.attachment ?? '')
      data.append('security_question_3_19', this.security_question_3_19 ?? '')
      data.append('security_question_3_20', this.security_question_3_20 ?? '')
      data.append('security_question_3_21', this.security_question_3_21 ?? '')
      data.append('security_question_3_22', this.security_question_3_22 ?? '')
      data.append('security_question_3_22_data', this.security_question_3_22_data?.attachment ?? '')
      data.append('refrigerant_type', this.refrigerant_type.id_refrigerant)

      try {
        const response = await Requests.createMaintenanceProject(data);
        console.log(response)
        if (response?.project_maintenance_addnew?.success == "true") {

          this.$swal('OK', 'Il progetto è stato salvato', 'success');
          this.$router.push('/saved_projects')
        } else {
          alert('Si è verificato un problema durante la creazione del progetto')
        }
      } catch (err) {
        console.log(err)
      }

      this.loading = false;
    },

    async getProjectSummary(id) {

      let request_data = new FormData();

      request_data.append('id', id);

      try {
        const response = await Requests.getProjectSummary(request_data);
        console.log(response);
        this.summary = response;
      } catch (err) {
        console.log(err)
      }
    },


    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Progetto Creato",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    validationCustomer() {
      return new Promise((resolve, reject) => {
        this.$refs.customerTab.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {

            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormDescription2() {
      return new Promise((resolve, reject) => {
        this.$refs.description2Rules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormSecurity() {
      return new Promise((resolve, reject) => {
        this.$refs.securityRules.validate().then((success) => {
          if (success) {
            this.createProject()
            this.tabIndex++;
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormTabs(i) {
      return new Promise((resolve, reject) => {
        this.$refs.tabRules[i].validate().then((success) => {
          if (success) {
            this.tabIndex++;
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormNames() {
      return new Promise((resolve, reject) => {
        this.$refs.nameRules.validate().then((success) => {
          if (success) {
            this.generateForm();
            this.$bvModal.hide("modal-impianti");
            this.disabled = true;
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },



    async getRefrigerantGasList() {
      try {
        const response = await Requests.getRefrigerantGasList();
        for (let gas in response.data) {
          this.refrigerantGases = response.data;
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getRefrigerantGasSuggestList() {
      try {
        const response = await Requests.getRefrigerantGasSuggestList();
        this.refrigerantGasesSuggest = response;
      } catch (err) {
        console.log(err)
      }
    },

    async uploadDocument(event, attachment) {
      if (event.target.files?.length > 0) {
        attachment.uploading = true;
        attachment.attachment = '';
        let formData = new FormData()
        formData.append('Filedata', event.target.files[0])
        try {
          const response = await Requests.uploadImage(formData);
          attachment.attachment = response.data;
        } catch (err) {
          console.log(err)
        }

        attachment.uploading = false;
      }
    },



    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }


      const filteredData = this.refrigerantGasesSuggest.filter(item => item.numero_refrigerante.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)

      this.filteredOptions = [{
        data: filteredData,
      }]
    },

    onSelect(item) {
      this.$bvModal.hide("modal-refrigerant-type");
      this.refrigerant_type = item.item
    },
  },

};
</script>

<style>
.nav-tabs .nav-link {
  justify-content: start !important;
}

.disabledTab {
  pointer-events: none !important;
}</style>

<style lang="scss">@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";</style>
