<template>
    <validation-provider #default="{ errors }" :name="field_name">

        <b-button :class="currentFile ? 'd-none' : 'd-block'" variant="outline-primary" pill @click="$refs.input_field.$el.childNodes[0].click()">
            Carica il file
            <b-form-file class="d-none" ref="input_field" accept="application/pdf" @input="$emit('new-file', $event)">
            </b-form-file>
        </b-button>

        <div v-if="uploading">
            <b-spinner variant="primary" class="ml-1" />
        </div>

        <div v-if="currentFile">
            <a target="_blank" :href="currentFile"><img height="100" :src="pdfIcon"></a>
        </div>

        <b-button class="mt-2 mr-2" :class="currentFile ? 'd-inline-block' : 'd-none'" variant="outline-primary" pill
            @click="$refs.input_field.$el.childNodes[0].click()">
            Sostituisci il file
        </b-button>
        <b-button class="mt-2" :class="currentFile ? 'd-inline-block' : 'd-none'" variant="outline-primary" pill
            @click="$emit('reset-file')">
            Rimuovi il file
        </b-button>


        <div>
            <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
        </div>
    </validation-provider>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { BFormFile, BButton, BSpinner } from "bootstrap-vue"
import pdfIcon from '@/assets/images/icons/pdf.png'

export default {
  components: {
    ValidationProvider,
    BFormFile,
    BButton,
    BSpinner
  },
  props: {
    uploading: {
      type: Boolean,
      default: false,
    },
    currentFile: {
      type: String,
      default: null,
    },
    field_name: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
        pdfIcon
    }
  }
}
</script>